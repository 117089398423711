.public {
  @import "public/lifeline";

  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 60px 1fr 160px;
  height: 100vh;

  .header {
    background-color: $brand-sea-green;

    &__wrapper {
      grid-area: header;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 1rem;
    }

    &__logo-wrapper {
      width: 80px;
    }

    &__logo {
      width: 45px;
      height: 45px;
    }

    &__title {
      margin: 0;
      color: $brand-yellow;
      text-transform: uppercase;
    }

    &__widgets {
      min-width: 80px;
      padding-right: 0;
      justify-content: flex-end;

      .user-widget {

        &__link {
          color: $brand-yellow;

          span {
            color: white;
          }
        }
      }
    }
  }

  .header-mobile {
    display: block !important;

    &__bar {

      .header-mobile-bar-item {

        .current-item {
          background-color: $brand-sea-green;
        }
      }
    }

    &__logo-wrapper {
      width: 100%;
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }

    &__logo {
      width: 45px;
      height: 45px;
      margin-right: 1rem;
    }

    &__title {
      margin: 0 1rem 0 0;
      color: $brand-yellow;
      flex-grow: 1;
      max-width: calc(100% - 120px);
      font-size: 2.2rem;

      @include media($nav-breakpoint, min) {
        text-align: center;
      }
    }

    &__widgets {

      .user-widget {

        &__link {
          color: $brand-yellow;
          font-size: 1rem;

          span {
            color: white;
          }
        }
      }
    }

    .header-mobile-toggle {

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }

      svg {

        &.md-icon-plus {
          color: $brand-sea-green;
          background-color: $brand-yellow;
        }
      }
    }
  }

  .main {
    grid-area: main;
  }

  .breadcrumbs-wrapper {
    background-color: black;
    position: relative;
    z-index: 20;

    .breadcrumbs {
      max-width: $wrapper-max-width;
      margin: 0 auto;
      font-size: 13px;
      font-weight: 500;
      padding: 0.3rem 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    background-color: white;
    border-top: 1px solid black;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__logo {
      width: 180px;
    }

    &__copy {
      color: black;
      margin: 0;
    }
  }

  .products {
    background-color: lighten($brand-gray-md, 30);

    &__wrapper {
      @include wrapper;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 2rem;
      padding: 2rem 0;
    }

    .card {
      background-color: white;

      &_active {

        .card__title {
          color: $brand-yellow;
          background-color: $brand-sea-green;

          &::after {
            border-width: 15px 10px 0 10px;
            border-color: $brand-yellow transparent transparent transparent;
          }
        }

        .card__subtitle {
          color: $brand-sea-green;
          background-color: $brand-yellow;
        }
      }

      &__title {
        color: $brand-sea-green;
        text-align: center;
        background-color: $brand-yellow;
        text-transform: uppercase;
        font-size: 1.2rem;
        margin: 0;
        padding: 0 1rem;
        position: relative;

        &::after {
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 0 10px 15px;
          border-color: transparent transparent transparent $brand-sea-green;
          position: absolute;top: 50%;
          right: 1rem;
          transform: translateY(-50%);
        }
      }

      &__subtitle {
        color: $brand-yellow;
        text-align: center;
        background-color: $brand-sea-green;
        text-transform: uppercase;
        font-size: 2rem;
        margin: 0;
        padding: 1rem;
        line-height: 2rem;
      }

      &__list {
        margin: 0;
        padding: 2rem;
        list-style: none;
        color: $brand-sea-green;
        font-size: 1.2rem;
        font-weight: bolder;

        li {

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .login-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease opacity;

    &_active {
      visibility: visible;
      opacity: 1;
    }

    &_inactive {
      visibility: hidden;
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.8;
    }

    &__box {
      position: relative;
      z-index: 10;
    }

    &__close-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: lighten($brand-slate, 10);
      position: absolute;
      top: -14px;
      right: -14px;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: black;
      }
    }
  }
}
