.spotlist-view {

  &__map {

    .leaflet-map {
      height: 400px;
    }

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-sea-green;
    }
  }
}
